import axios from "axios";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import React, { useState, useEffect, useRef } from "react";
import { useManaWallet } from "../custom-providers/useWallet";
import { connect } from "react-redux";
import { items } from "../assets/ShopItems";
import { BrowserProvider, Contract, ethers, JsonRpcProvider } from "ethers";
import Abi from "../assets/contracts/ManaPassportContractBuild.json";
import ccdMetadata from "../assets/ccdData.json";
import transferNft from "../services/zkSync.service";
const MyNfts = ({ navbarSize }) => {
  const { walletAddress, coinToken } = useManaWallet();
  const [nfts, setNfts] = useState([]);
  const [selectedNftId, setSelectedNftId] = useState([]);
  const [nftCount, setNftCount] = useState(0);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const inputRef = useRef(null);
  const [isZkTransaction, setIsZkTransaction] = useState(false);
  const [isCcdTransaction, setIsCcdTransaction] = useState(false);
  const zkContractAddress = "0x44f3812d6b28145c7a6ad6e23df8bef3c039df28";
  const browserProvider = new BrowserProvider(window.ethereum);

  const handleInputChange = (e) => {
    setRecipientAddress(e.target.value);
  };

  const getSigner = async () => {
    const signer = await browserProvider.getSigner();
    return signer;
  };

  const handleSubmit = async () => {
    const nftTransaction = await transferNft(selectedNftId, recipientAddress);
    if (nftTransaction === true) {
      setShowForm(false);
      setIsSubmitted(true);
      setSelectedNftId([]);
      setNftCount(0);
    } else {
      console.log("Error on transfer NFT");
    }
  };
  const concordiumTransaction = () => {
    alert("A1");
  };

  const fetchDataConcordium = async () => {
    const endpoint = "https://api-ccdscan.mainnet.concordium.software/graphql";

    const query = `
    query (
      $address: String!,
      $firstAccountReward: Int,
      $firstAccountToken: Int,
      $firstAccountStatement: Int
    ) {
      accountByAddress(accountAddress: $address) {
        rewards(first: $firstAccountReward) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            block {
              blockHash
            }
            id
            timestamp
            rewardType
            amount
          }
        }
        tokens(first: $firstAccountToken) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            balance
            contractIndex
            contractSubIndex
            tokenId
            token {
              metadataUrl
              tokenAddress
              contractAddressFormatted
            }
          }
        }
        accountStatement(first: $firstAccountStatement) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            reference {
              ... on Block {
                blockHash
              }
              ... on Transaction {
                transactionHash
              }
            }
            timestamp
            entryType
            amount
            accountBalance
          }
        }
      }
    }
  `;

    const variables = {
      address: walletAddress,
      firstAccountReward: 10,
      firstAccountToken: 10,
      firstAccountStatement: 10,
    };

    try {
      const response = await axios.post(endpoint, {
        query,
        variables,
      });
      const fetchedNfts = await response?.data?.data?.accountByAddress?.tokens?.nodes;
      let nftsArr = [];
      fetchedNfts?.map((nft) => {
        nftsArr = [
          ...nftsArr,
          {
            tokenId: nft.tokenId.replace(/^0+/, ""),
            name: ccdMetadata.concordiumMetadata.name,
            originalImageUrl: ccdMetadata.concordiumMetadata.originalImageUrl,
          },
        ];
      });
      setNfts(nftsArr);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchRawDataFromUrl = async () => {
    try {
      const response = await fetch(
        "https://d1790zh956hv5i.cloudfront.net/metadata-potions/00000006"
      );

      // Check if the response is ok (status code 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };

  useEffect(() => {
    if (walletAddress && coinToken === "ZK") {
      setNftCount(0);
      setSelectedNftId([]);
      setIsZkTransaction(true);
      setIsCcdTransaction(false);
      fetchNFTs(walletAddress);
      // fetchNFTs("0x4439fdd338Fd656bDf18d56b2967D022f286E22b");
    } else if (walletAddress && coinToken === "CDC") {
      setNftCount(0);
      setSelectedNftId([]);
      setIsZkTransaction(false);
      setIsCcdTransaction(true);
      fetchDataConcordium();
    } else {
      setNftCount(0);
      setSelectedNftId([]);
      setNfts([]);
    }
  }, [walletAddress, coinToken]);

  const transferAssets = () => {
    setShowForm(true);
  };

  const handleItemClick = (itemId) => {
    if (selectedNftId.includes(itemId)) {
      setSelectedNftId(selectedNftId.filter((id) => id !== itemId));
      let quantity = nftCount - 1;
      setNftCount(quantity);
      if (selectedNftId.length === 0) {
        setShowForm(false);
      }
    } else {
      setSelectedNftId([...selectedNftId, itemId]);
      let quantity = nftCount + 1;
      setNftCount(quantity);
      setIsSubmitted(false);
    }
  };

  const fetchTokenMetadata = async (tokenUri) => {
    try {
      const response = await axios.get(tokenUri, {
        withCredentials: false,
      });
      return response.data;
    } catch (error) {
      console.error("Error Fetching token metadata", error.message);
      return null;
    }
  };
  const fetchNFTs = async (address) => {
    try {
      // const signer = await getSigner();
      // const contract = new Contract(zkContractAddress, Abi.abi, signer);
      // const transferInFilter = contract.filters.Transfer(null, address);
      // const transferOutFilter = contract.filters.Transfer(address, null);

      // const [incomingLogs, outgoingLogs] = await Promise.all([
      //     contract.queryFilter(transferInFilter),
      //     contract.queryFilter(transferOutFilter),
      // ]);
      // const incomingTokenId = incomingLogs.map((log) => {
      //     const parsedLog = contract.interface.parseLog(log);
      //     return Number(parsedLog.args[2]);
      // });
      // const outgoingTokenId = outgoingLogs.map((log) => {
      //     const parsedLog = contract.interface.parseLog(log);
      //     return Number(parsedLog.args[2]);
      // });

      // const tokenIds = incomingTokenId.filter(
      //     (tokenId) => !outgoingTokenId.includes(tokenId)
      // );
      // const assets = [];
      // for (const tokenId of tokenIds) {
      //     const currentOwner = await contract.ownerOf(tokenId);
      //     if (currentOwner.toLowerCase() === address.toLowerCase()) {
      //         const tokenUri = await contract.tokenURI(tokenId);
      //         console.log("Estas son las URL de la metadata", tokenUri)
      //         const metadata = await fetchTokenMetadata(tokenUri);
      //         console.log("metadata", metadata);
      //     }
      // }

      const response = await axios.get(
        `https://api.zkmarkets.com/zksync-era/collections/0x44f3812d6b28145c7a6ad6e23df8bef3c039df28/nfts`,
        {
          params: {
            page: 0,
            size: 21,
            address: "0x44f3812d6b28145c7a6ad6e23df8bef3c039df28",
            search: "",
            // ownerAddress: '0xef5F5FC1D38de65585C802425C925bA6854fc6b2',
            ownerAddress: address,
            sort: "priceEth",
            order: "ASC",
          },
        }
      );

      if (response.data.length > 0) {
        setNfts(response.data);
      } else {
        setNfts([]);
      }
    } catch (error) {
      console.error("Error fetching NFTs:", error);
    }
  };

  return (
    <div>
      <NavBar />
      <SideBar />

      <div
        className={
          navbarSize === "expand"
            ? "custom-containerHome"
            : "custom-container2Home"
        }
      >
        <div
          style={{
            marginTop: 50,
            marginLeft: 30,
            marginRight: 30,
            height: "100vh",
          }}
          className="pixelFont"
        >
          {nftCount > 0 && (
            <div>
              {nftCount > 0 && (
                <div className="transfer-assets" onClick={transferAssets}>
                  <p className="transfer-assets-text">
                    Transfer Assets: {nftCount}
                  </p>{" "}
                </div>
              )}

              {showForm && (
                <div className="form-container">
                  <input
                    type="text"
                    ref={inputRef}
                    id="scrolling-placeholder"
                    style={{
                      width: `${
                        recipientAddress.length < 16
                          ? 18
                          : recipientAddress.length + 2
                      }ch`,
                      paddingLeft: "10px",
                    }}
                    className="wallet-input"
                    placeholder="recipient's wallet"
                    value={recipientAddress}
                    onChange={handleInputChange}
                    disabled={isSubmitted || !showForm}
                  />
                  {!isSubmitted && isZkTransaction && (
                    <button className="submit-button" onClick={handleSubmit}>
                      Send
                    </button>
                  )}
                  {!isSubmitted && isCcdTransaction && (
                    <button
                      className="submit-button"
                      onClick={concordiumTransaction}
                    >
                      Send
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          <h1
            style={{
              color: "#8c51e1",
              fontSize: "40px",
              fontWeight: "bold",
            }}
          >
            Mana Assets
          </h1>
          {nfts.length === 0 && (
            <p key="No Items" className="no-nfts-message">
              You don't have any assets yet, what are you waiting for?
            </p>
          )}
          <div className="market-items-grid">
            {nfts.length > 0
              ? nfts.map((nft) => (
                  <div
                    key={nft.tokenId}
                    className={
                      selectedNftId.includes(nft.tokenId)
                        ? "nft-item-selected"
                        : "nft-item"
                    }
                    onClick={() => handleItemClick(nft.tokenId)}
                  >
                    <img src={nft.originalImageUrl} alt={nft.name} />
                    <p>
                      {nft.name} #{nft.tokenId}
                    </p>
                  </div>
                ))
              : items.map((nft) => (
                  <div
                    key={nft.tokenId}
                    className="nft-item greyed-out"
                    style={{
                      cursor: "default",
                      pointerEvents: "none",
                    }}
                  >
                    <img src={nft.image} alt={nft.name} />
                    <p>{nft.name} #1</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.auth.user, navbarSize: state.alerts.navbarSize };
};

export default connect(mapStateToProps)(MyNfts);
